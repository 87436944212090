<template>
  <html v-bind="head.htmlAttrs || {}">
    <head>
      <title>{{ title }}</title>
      <link
        v-for="link in head.link"
        :id="link.id"
        :key="link.id"
        :rel="link.rel"
        :href="link.href"
        :hreflang="link.hreflang"
      >
      <meta v-for="meta in head.meta" :id="meta.id" :key="meta.id" :property="meta.property" :content="meta.content">
    </head>
    <body>
      <v-app>
        <v-main class="main-container">
          <v-container class="ma-0 pa-0 login--container h-100 d-flex a-i-c" fluid>
            <slot class="content" />
          </v-container>
        </v-main>
        <!-- @vue-ignore -->
        <TheNotifications
          position="top right"
          width="500"
          pause-on-hover
        />
      </v-app>
    </body>
  </html>
</template>

<script setup lang="ts">

import * as Sentry from "@sentry/vue";

const { t, locale } = useI18n();
const route = useRoute();
const appConfig = useAppConfig();

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: "id",
  addSeoAttributes: true
});

const title = computed<string>(() => {
  const part = route.meta.title ? t(route.meta.title as string) : "";
  return part ? `${appConfig.baseTitle} - ${part}` : appConfig.baseTitle;
});

onMounted(() => {
  if (window?.cookieBar) {
    window?.cookieBar?.changeLanguage(locale.value);
  }
  Sentry.setTag("page_locale", locale.value);
});
</script>
